.bwn_listing_permotion_section .bw_mont_inner_heading_box {
	min-height: 147px;
}
.bwn_b2c_pricing_section .bw_new_mont_hading_box {
	padding: 15px;
	min-height: 215px;
}
.bwn_seo_pricing_section .bw_new_mont_hading_box {
	padding: 15px;
	min-height: 216px;
}

@media(min-width:320px) and (max-width:767px){
	.bw_custome_container_pricing{
		padding-left: 0;
		padding-right: 0;
	}
}

@media(min-width:320px) and (max-width:1024px){
.bw_djwd_table_mobileview td {
	line-height: 20px;
	font-size: 13px;
}
}
@media (min-width: 1150px) and (max-width: 1350px){
.bwn_seo_pricing_section .bw_new_mont_hading_box {
	min-height: 160px;
}
.bwn_b2c_pricing_section .bw_new_mont_hading_box {
	padding: 15px;
	min-height: 161px;
}
.bw_pricing_montly_table tbody tr td {
	line-height: 1.3;
}
.bwn_listing_permotion_section .bw_mont_inner_heading_box {
	min-height: 113px;
	padding: 15px !important;
}
.bw_btn_price_start {
	padding: 8px 60px !important;
	font-size: 11px;
}
}

@media (min-width: 1440px) and (max-width: 1650px){
.bwn_seo_pricing_section .bw_new_mont_hading_box {
	min-height: 170px;
}.bwn_b2c_pricing_section .bw_new_mont_hading_box {
	padding: 15px;
	min-height: 170px;
}
.bw_pricing_montly_table tbody tr td {
	line-height: 1.3;
}
.bwn_listing_permotion_section .bw_mont_inner_heading_box {
	min-height: 120px !important;
} 
.bwn_listing_permotion_section .bw_mont_inner_heading_box {
	min-height: 120px;
}
.bw_btn_price_start {
	padding: 8px 60px !important;
	font-size: 11px;
}
}

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.\/cr-gems-api-integration .bw_header_section, .\/cr-gems-api-integration .bwn_footer_section {
	display: none;
}
.bw_cr_gems_api_integration_Section_new .bw_banner_heading {
	margin: 0 auto 15px;
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
	color: #38568E;
	text-align: center;
	text-transform: uppercase !important;
	font-family: "Lora", serif;
}
.bw_cr_gems_api_integration_Section_new .bw_banner_sub_heading {
	margin: 0 auto;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #000;
	text-align: center;
	padding: 0 15px;
}
.bw_cr_gems_api_integration_Section_new.bw_cr_gems_section .bw_all_banner {
	padding: 100px 0 80px;
	background-image: none !important;
	background-color: transparent !important;
}
.bw_cr_api_integration_text_box ul {
	padding-left: 20px;
	margin-top: 15px;
}
.bw_cr_api_integration_text_box ul li{
    position:  relative;
}
.bw_cr_api_integration_text_box ul li::before {
	content: '';
	left: 0;
	right: 0;
    position: absolute;
    background-image: url('../images/integrations/right-arrow-img.png');
    width: 10px;
    width: 10px;
    background-repeat: no-repeat;
    height: 8px;
    top: 10px;
    left: -20px;
}
.bw_cr_api_integration_text_box h2 {
	font-size: 42px;
	font-weight: 400;
	line-height: 50px;
	text-transform: uppercase;
	color: #38568E;
	font-family: "Lora", serif !important;
}
.bw_cr_api_integration_text_box p{
    font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	color: #212529;
    font-family: 'Poppins';
}
.bw_cr_api_integration_text_box strong{
    font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	color: #212529;
    font-family: 'Poppins';
}
.bw_cr_api_integration_text_box ul li{
    font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	color: #212529;
    font-family: 'Poppins';
}
.bw_cr_gems_api_integration_Section_new .bw_gems_video_section {
	margin-bottom: 60px;
}
.bw._ce_gems_api_integration_lap_top_img_box {
	text-align: center;
}
.bw_cr_gems_api_integration_Section_second .colorBlue {
	font-size: 25px;
	font-weight: 400;
	line-height: 30px;
	color: #38568E;
	text-transform: uppercase;
	font-family: "Lora", serif !important;
}
.bw_cr_gems_api_integration_Section_second .bw_contact_us_form button, .btnStyle {
	background-color: #38568E;
	color: #fff;
	border: 1px solid #38568E;
}

@media(min-width:320px) and (max-width:767px){
	.cr_img_order2{
		order: 1;
	}
	.cr_img_order1{
		order: 2;
	}
	.bw_cr_gems_api_integration_Section_new .bw_banner_heading {
		margin: 0 auto 15px;
		line-height: 35px;
		padding-bottom: 0;
		font-size: 22px !important;
	}
	.bw_cr_gems_api_integration_Section_new .bw_banner_sub_heading {
		font-size: 14px;
		line-height: 22px;
		padding: 0 15px;
	}
	.bw_cr_gems_api_integration_Section_new.bw_cr_gems_section .bw_all_banner {
		padding: 80px 0 30px;
	}
	.bw_ce_gems_api_integration_lap_top_img_box img {
		margin-bottom: 30px;
	}
	.bw_cr_api_integration_text_box h2 {
		font-size: 21px;
		line-height: 30px;
	}
	.bw_cr_api_integration_text_box p {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_api_integration_text_box ul li {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_api_integration_text_box ul li::before {
		top: 8px;
	}
	.bw_cr_api_integration_text_box strong {
		font-size: 16px;
		line-height: 22px;
	}

}
@media(min-width:768px) and (max-width:991px){
	.cr_img_order2{
		order: 1;
	}
	.cr_img_order1{
		order: 2;
	}
	.bw_cr_gems_api_integration_Section_new .bw_banner_heading {
		margin: 0 auto 15px;
		line-height: 40px;
		padding-bottom: 0;
		font-size: 35px !important;
	}
	.bw_cr_gems_api_integration_Section_new .bw_banner_sub_heading {
		font-size: 14px;
		line-height: 22px;
		padding: 0 15px;
	}
	.bw_cr_gems_api_integration_Section_new.bw_cr_gems_section .bw_all_banner {
		padding: 80px 0 30px;
	}
	.bw_ce_gems_api_integration_lap_top_img_box img {
		margin-bottom: 30px;
	}
	.bw_cr_api_integration_text_box h2 {
		font-size: 22px;
		line-height: 30px;
	}
	.bw_cr_api_integration_text_box p {
		font-size: 14px;
		line-height: 22px;
	}

	
	.bw_cr_api_integration_text_box ul li {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_api_integration_text_box ul li::before {
		top: 8px;
	}
	.bw_cr_api_integration_text_box strong {
		font-size: 16px;
		line-height: 22px;
	}
	.apiSecUnder.cr_gems_sectionn h3 {
		font-size: 22px;
		line-height: 30px;
	}
	.apiSecUnder {
		border-radius: 50px 50px 0px 0px;
		padding: 40px 45px;
	}
}
@media(min-width:992px) and (max-width:1024px){
	.bw_cr_gems_api_integration_Section_new .bw_banner_heading {
		margin: 0 auto 15px;
		line-height: 40px;
		padding-bottom: 0;
		font-size: 35px !important;
	}
	.bw_cr_gems_api_integration_Section_new .bw_banner_sub_heading {
		font-size: 14px;
		line-height: 22px;
		padding: 0 15px;
	}
	.bw_cr_gems_api_integration_Section_new.bw_cr_gems_section .bw_all_banner {
		padding: 80px 0 50px;
	}

	.bw_cr_api_integration_text_box h2 {
		font-size: 25px;
		line-height: 30px;
	}
	.bw_cr_api_integration_text_box p {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_api_integration_text_box ul li {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_api_integration_text_box ul li::before {
		top: 8px;
	}
	.bw_cr_api_integration_text_box strong {
		font-size: 16px;
		line-height: 22px;
	}
	.apiSecUnder.cr_gems_sectionn h3 {
		font-size: 22px;
		line-height: 30px;
	}
	.apiSecUnder {
		border-radius: 50px 50px 0px 0px;
		padding: 40px 45px;
	}
}
@media(min-width:1025px) and (max-width:1199px){}
@media(min-width:1200px) and (max-width:1440px){
	.bw_cr_gems_api_integration_Section_new .bw_banner_heading {
		margin: 0 auto 10px;
		font-size: 38px;
		line-height: 38px;
		padding-bottom: 0;
	}
	.bw_cr_gems_api_integration_Section_new .bw_banner_sub_heading {
		font-size: 14px;
		line-height: 25px;
		padding: 0 15px;
	}
	.bw_cr_api_integration_text_box h2 {
		font-size: 33px;
		line-height: 40px;
	}
	.bw_cr_api_integration_text_box p {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_api_integration_text_box strong {
		font-size: 18px;
		line-height: 20px;
	}
	.bw_cr_api_integration_text_box ul li {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_api_integration_text_box ul li::before {
		top: 7px;
		left: -20px;
	}
	.bw_cr_gems_api_integration_Section_second .colorBlue {
		font-size: 22px;
		line-height: 28px;
	}
	.bw_cr_gems_api_integration_Section_second .fs15 {
		font-size: 14px;
		line-height: 22px;
	}
	.bw_cr_gems_api_integration_Section_second button {
		padding: 10px 30px !important;
		font-size: 12px !important;
		margin-top: 10px;
	}

}
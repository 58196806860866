.switcher-header .logo img {
    width: 70px;
    height: 50px;
    object-fit: cover;
}

.switcher-header .theme-selector select {
    border: 1px solid #e0e0e0;
    background: #FCFCFC;
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #8C8C8C;
    width: 60%;
    box-shadow: none !important;
    outline: none !important;
}

.switcher-header .theme-selector {
    padding: 0 25px;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    height: 100%;
    align-items: center;
    display: flex;
}

.switcher-header .responsive svg {
    fill: #c6c6c6;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.switcher-header .responsive svg:hover, .switcher-header .responsive li.active svg {
    fill: #0c276a;
}

.switcher-header .links a, .switcher-header .links a:active {
    position: relative;
    border: 1px solid #3d63c3 !important;
    border-bottom: 3px solid #3d63c3 !important;
    color: #000 !important;
    background: linear-gradient(to bottom,#3d63c3 0%,#3d63c3 100%) !important;
    text-shadow: 0 1px 0 rgb(49 49 49 / 70%);
    box-shadow: 0 1px 0 rgba(0,0,0,.2) !important;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px;
    font-size: 14px;
    color: #FFF !important;
}

/* .switcher-header .links a span:first-child {
    text-align: center;
    background: rgba(0,0,0,.05);
    line-height: 33px;
    height: 100%;
    display: inline-block;
    width: 35px;
    margin-right: 5px;
} */

.switcher-header .links a span svg {
    fill: #FFF;
    width: 24px;
    height: 24px;
}

.switcher-header .switcher-inner {
    display: grid;
    grid-template-columns: 7% 35% 40% 18%;
    align-items: center;
}
/* 
section.bw_header_section, .bwn_footer_section {
    display: none;
} */

.switcher-header .responsive ul {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    margin-left: 130px;
    margin-bottom: 0;
}

.theme-selector::after,
.responsive::after,
.links::after {
    position: absolute;
    transform: translateY(-40%);
    height: 40px;
    width: 100px;
    content: '';
}

.theme-selector::after {
    left: 65%;
    top: 40%;
    background: url('../images/theme-review/select.png') no-repeat center/contain;
}

.responsive::after {
    left: 20px;
    top: 40%;
    background: url('../images/theme-review/responsive.png') no-repeat center/contain;
}

.links::after {
    left: -95px;
    top: 40%;
    background: url('../images/theme-review/download.png') no-repeat center/contain;
    height: 30px;
    width: 90px;
}

.themePreviewUrl {
    display: block;
    width: 100%;
    height: calc(100vh - 50px);
    margin: 50px auto 0;
    border: none;
    transition: 0.35s margin-top ease, 0.3s height ease;
}

.rotate-90 {
    transform: rotatez(90deg);
}

.close_btn {
    position: fixed;
    top: 0px;
    right: 0;
    z-index: 9999;
    transition: 0.35s all ease-in-out;
}

.close_btn button {
    width: 50px;
    height: 50px;
    border: none;
    transition: 0.35s all ease-in-out;
}

.close_btn button svg {
    width: 25px;
    height: 25px;
    transition: 0.35s all ease-in-out;
}

.switcher-header {
    transform: translate3d(0,0,0);
    transition: 0.35s all ease-in-out;
    opacity: 1;
}

.hidden-switcher .switcher-header {
    transform: translate3d(100%,-100%,0);
    opacity: 0;
}

.hidden-switcher .close_btn button svg {
    transform: rotateZ(45deg);
}

.hidden-switcher + .themePreviewUrl {
    margin-top: 0;
    height: 100vh;
}

@media only screen and (max-width: 1200px) {
    .switcher-header .container {
        max-width: 100% !important;
    }

    .responsive::after {
        display: none;
    }

    .switcher-header .responsive ul {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 1125px) {
    .switcher-header .switcher-inner {
        grid-template-columns: 8% 40% 35% 20%;
    }

    .links {
        text-align: right;
        margin-right: 25px;
    }

    .links::after {
        left: auto;
        right: 160px;
    }

    .switcher-header .logo {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 1024px) {
    .switcher-header .switcher-inner {
        grid-template-columns: 7% 40% 30% 23%;
    }

    .links {
        margin-right: 0;
    }
}

@media only screen and (max-width: 992px) {
    .links::after, .theme-selector::after {
        display: none;
    }
}

@media only screen and (max-width: 870px) {
    .switcher-header .switcher-inner {
        grid-template-columns: 10% 35% 25% 30%;
    }

    .switcher-header .theme-selector select {
        width: 90%;
    }

    .switcher-header .theme-selector {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 700px) {
    .responsive {
        visibility: hidden;
    }
}

@media only screen and (max-width: 650px) {
    .switcher-header .links a span {
        margin: 0 !important;
    }

    .switcher-header .links a {
        padding-right: 0;
    }

    .switcher-header .links a span:last-child {
        display: none;
    }

    .switcher-header .switcher-inner {
        grid-template-columns: 10% 50% 0% 40%;
    }

    .switcher-header .theme-selector {
        border: none;
    }

    .links {
        margin-right: 0;
    }
}

@media only screen and (max-width: 450px) {
    .switcher-header .switcher-inner {
        grid-template-columns: 18% 50% 0% 32%;
    }
}